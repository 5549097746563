<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Texts') }}
    </PageTitle>
    <div class="w-full">
      <t-card class="w-full" v-if="adminPostsData">
        <DataTable
          :headers="tableHeaders"
          :data="adminPostsData"
          @edit="pushToEdit"
          @delete="removePostAction"
        >
          <template #title="{ item }">
            {{ item.title.length<50 ? item.title: item.title.substring(0,47)+'...' }}
          </template>
        </DataTable>
      </t-card>
    </div>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import PageTitle from "@/layouts/components/pages/PageTitle";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AdminPosts",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    PageTitle,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:PostActions',
          value: 'actions',
        },
        {
          name: 'LABEL:PostFeaturedImage',
          value: 'image',
          isImage: true,
        },
        {
          name: 'LABEL:PostTitle',
          value: 'title'
        },
	      {
		      name: 'LABEL:PostCategory',
		      value: 'categorySlug'
	      },
        {
          name: 'LABEL:PostPublishedAt',
          value: 'publishedAtDate',
        },
        {
          name: 'LABEL:PostAuthor',
          value: 'authorName',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('admin', ['adminPostsData', 'postRemoving']),
  },
  methods: {
    ...mapActions('admin', ['fetchAdminPostsData', 'removeAdminPost']),
    removePostAction(id) {
      this.removeAdminPost({postId: id});
    },
    pushToEdit(id) {
      router.push({name: 'admin-articles-show', params: {id: id}})
    },
  },
  mounted() {
    this.fetchAdminPostsData();
  }
}
</script>
